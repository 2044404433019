@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100..900;1,100..900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .animation-delay-2000 {
    animation-delay: 2s;
  }
  .animation-delay-4000 {
    animation-delay: 4s;
  }
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}
.dark body {
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*/GRADIENTS/*/
.gradient-45 {
  background: #d728d7;
  background: linear-gradient(
    45deg,
    #d7282f,
    #d72880 33.33%,
    #d728d7 66.66%,
    #8028d7 100%
  );
}

input[type="range"]::-webkit-slider-thumb {
  width: 0.75rem;
  -webkit-appearance: none;
  appearance: none;
  height: 0.75rem;
  cursor: ew-resize;
  background: white;
  box-shadow: -405px 0 0 400px #6366f1;
  border: 1px solid #9ca3af;
  border-radius: 50%;
  position: relative;
  z-index: 1;
}

.dark input[type="range"]::-webkit-slider-thumb {
  background: black;
  border: 1px solid #4b5563;
}

/*LIVE CHAT BUTTON POSITION*/
#designstudio-button {
  bottom: 4rem !important;
  z-index: 30 !important;
}
